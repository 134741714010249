import React, { useState, useEffect } from 'react';
import firebase from '../../utils/firebase';
import { Container, TextField, Grid, Button } from '@material-ui/core';

export default function AdminAbout () {

    const [about,setabout] = useState('')
    const [image,setimage] = useState(null)

    useEffect(() => {
        firebase.getDocument('page','about').onSnapshot(snap => {
            setabout(snap.data())
        })
    },[])

    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        onChange={e => setimage(e.target.files[0])}
                        label="Imagen de portada"
                        type="file"
                    />
                    <Button fullWidth variant="outlined" onClick={handleSubmit}>Subir Imagen</Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    {about.image ? <picture>
                        <img style={{ width: '100%' }} src={about.image} alt="Brandon Linares"/>
                    </picture> : ''}
                </Grid>
            </Grid>
            <TextField
                label="Español"
                value={about.espanol}
                onChange={e => firebase.updateDoc('page','about','espanol',e.target.value)}
                variant="outlined"
                fullWidth
                multiline
                rows="8"
                style={{ marginBottom: '1rem' }}
            />
            <TextField
                label="Inglés"
                value={about.ingles}
                onChange={e => firebase.updateDoc('page','about','ingles',e.target.value)}
                variant="outlined"
                fullWidth
                multiline
                rows="8"
                style={{ marginBottom: '1rem' }}
            />
            <TextField
                label="Frances"
                value={about.frances}
                onChange={e => firebase.updateDoc('page','about','frances',e.target.value)}
                variant="outlined"
                fullWidth
                multiline
                rows="8"
            />
        </Container>
    )

    async function handleSubmit () {
        try {
            if (image) {
                firebase.addFileDoc('page','about','image',image).then(() => {
                    alert('Imágen agregada')
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }
}