import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import CollectionsIcon from '@material-ui/icons/Collections';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import PhotoIcon from '@material-ui/icons/Photo';
import LocalPhoneIcon from '@material-ui/icons/LocalPhone';
import Home from '../pages/Home';
import Box from '@material-ui/core/Box';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import MovieIcon from '@material-ui/icons/Movie';

import About from '../pages/About';
import Login from '../pages/Login';
import Contact from '../pages/Contact';
import moment from 'moment';
import Projects from '../pages/Projects';
import Stories from '../pages/Stories';
import Expositions from '../pages/Expositions';
import Single from '../pages/Single';
import ShortFil from '../pages/ShortFilm';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        [theme.breakpoints.up('sm')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        backgroundColor: '#F2D680',
        [theme.breakpoints.up('sm')]: {
            zIndex: theme.zIndex.drawer + 1,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
        display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        borderRight: 'none',
        backgroundColor: '#D9A3C6'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
}));

function Client(props) {
    const { container } = props;
    const classes = useStyles();
    const theme = useTheme();
    const lastPar = props.location.pathname.split('/').pop()
    const lang = (lastPar === 'esp' ? 'esp' : lastPar === 'fra' ? 'fra' : 'eng');
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const loc = props.location.pathname.split('/')[1]

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <picture style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
                <Link to={`/${lang}`}>
                    <img style={{ width: '10rem' }} src="/images/logo.png" alt="Brandon Linares León"/>
                </Link>
            </picture>
            <List style={{ marginTop: '1rem' }}>
                <ListItem selected={loc === 'about' ? true : false} component={Link} to={`/about/${lang}`} button>
                    <ListItemIcon><PersonIcon style={{ fontSize: '1.2rem' }} /></ListItemIcon>
                    <ListItemText primary="Brandon Linares" />
                </ListItem>
                {/* <ListItem selected={loc === 'projects' ? true : false} component={Link} to={`/projects/${lang}`} button>
                    <ListItemIcon><CollectionsIcon style={{ fontSize: '1.2rem' }} /></ListItemIcon>
                    <ListItemText primary={lang === 'esp' ? 'Proyectos' : lang === 'eng' ? 'Projects' : 'Les Projets'} />
                </ListItem> */}
                <ListItem selected={loc === 'stories' ? true : false} component={Link} to={`/stories/${lang}`} button>
                    <ListItemIcon><ImportContactsIcon style={{ fontSize: '1.2rem' }} /></ListItemIcon>
                    <ListItemText primary={lang === 'esp' ? 'Historias' : lang === 'eng' ? 'Stories' : 'Histoires'} />
                </ListItem>
                <ListItem selected={loc === 'expositions' ? true : false} component={Link} to={`/expositions/${lang}`} button>
                    <ListItemIcon><PhotoIcon style={{ fontSize: '1.2rem' }} /></ListItemIcon>
                    <ListItemText primary={lang === 'esp' ? 'Fotos' : lang === 'eng' ? 'Photos' : 'Photos'} />
                </ListItem>
                <ListItem selected={loc === 'short-film' ? true : false} component={Link} to={`/short-film/${lang}`} button>
                    <ListItemIcon><MovieIcon style={{ fontSize: '1.2rem' }} /></ListItemIcon>
                    <ListItemText primary={lang === 'esp' ? 'Cortometraje' : lang === 'eng' ? 'Short Film' : 'Court Métrage'} />
                </ListItem>
                <ListItem selected={loc === 'contact' ? true : false} component={Link} to={`/contact/${lang}`} button>
                    <ListItemIcon><LocalPhoneIcon style={{ fontSize: '1.2rem' }} /></ListItemIcon>
                    <ListItemText primary={lang === 'esp' ? 'Contacto' : lang === 'eng' ? 'Contact' : 'Contact'} />
                </ListItem>
            </List>
            <Box marginTop="1rem" display="flex" justifyContent="space-around">
                <IconButton component="a" href="">
                    <FacebookIcon />
                </IconButton>
                {/* <IconButton>
                    <TwitterIcon />
                </IconButton> */}
                <IconButton component="a" href="https://www.instagram.com/leon.l.i.l/" target="_blank" rel="noopener noreferrer">
                    <InstagramIcon />
                </IconButton>
                {/* <IconButton>
                    <YouTubeIcon />
                </IconButton> */}
            </Box>
            <Box marginTop="1rem" display="flex" alignItems="center" justifyContent="space-around">
                <LanguageIcon color="disabled" style={{ marginRight: '1rem' }} />
                <Link style={{ color: '#212121' }} to="/esp">Esp</Link> / 
                <Link style={{ color: '#212121' }} to="/eng">Ing</Link> /
                <Link style={{ color: '#212121' }} to="/fra">Fra</Link>
            </Box>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    className={classes.menuButton}
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap>
                    Brandon Linares León
                </Typography>
                </Toolbar>
            </AppBar>
            <nav className={classes.drawer} aria-label="mailbox folders">
                <Hidden smUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
                <Hidden xsDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        style={{ backgroundColor: "#68A6A0" }}
                        variant="permanent"
                        open
                    >
                        {drawer}
                    </Drawer>
                </Hidden>
            </nav>
            <main className={classes.content}>
                <div className={classes.toolbar} />
                <Switch>
                    <Route path="/" render={props => <Home lang={lang} {...props} />} exact={true} />
                    <Route path="/esp" render={props => <Home lang={lang} {...props} />} exact={true} />
                    <Route path="/eng" render={props => <Home lang={lang} {...props} />} exact={true} />
                    <Route path="/fra" render={props => <Home lang={lang} {...props} />} exact={true} />
                    <Route path="/about/esp" render={props => <About lang={lang} {...props} />} />
                    <Route path="/about/eng" render={props => <About lang={lang} {...props} />} />
                    <Route path="/about/fra" render={props => <About lang={lang} {...props} />} />
                    <Route path="/projects" render={props => <Projects lang={lang} {...props} />} />
                    <Route path="/stories" render={props => <Stories lang={lang} {...props} />} />
                    <Route path="/expositions" render={props => <Expositions lang={lang} {...props} />} />
                    <Route path="/short-film" render={props => <ShortFil lang={lang} {...props} />} />
                    <Route path="/contact" render={props => <Contact lang={lang} {...props} />} />
                    <Route path="/item/:id" render={props => <Single lang={lang} {...props} />} />
                    <Route path="/log-in" render={props => <Login lang={lang} {...props} />} />
                </Switch>
                <Box marginTop="1rem" padding="1rem">
                    <Typography style={{ fontSize: '.8rem' }} align="center">
                        &copy; {moment().format('YYYY')} Todos los derechos reservados. <Link style={{ color: '#212121' }} to="/admin">Panel de Administración</Link>.
                    </Typography>
                </Box>
            </main>
        </div>
    );
}

Client.propTypes = {
    container: PropTypes.instanceOf(typeof Element === 'undefined' ? Object : Element),
};

export default Client;