import React, { useState, useEffect } from 'react';
import firebase from '../utils/firebase';
import { Container, Typography } from '@material-ui/core';

export default function About ({ lang }) {

    const [about,setabout] = useState('')

    useEffect(() => {
        firebase.getDocument('page','about').onSnapshot(snap => {
            setabout(snap.data())
        })
    },[])

    return (
        <Container maxWidth="sm">
            {
                about.image ? <picture style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '2rem', borderRadius: '50%', overflow: 'hidden', maxWidth: '20rem', margin: '2rem auto' }}>
                    <img style={{ width: '100%', margin: '0 auto' }} src={about.image} alt="Brandon Linares"/>
                </picture> : ''
            }
            <Typography align="center" variant="h3" component="h1">Brandon Linares León</Typography>
            <Typography style={{ marginTop: '2rem', whiteSpace: 'pre-line' }} align="justify" variant="body1">
                {
                    lang === 'esp' ? about.espanol : lang === 'fra' ? about.frances : about.ingles
                }
            </Typography>
        </Container>
    )
}