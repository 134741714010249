import React from 'react'
import { Container, Grid, Typography } from '@material-ui/core'
import { css } from '@emotion/core'

export default function ShortFil ({ lang }) {

    return (
        <Container maxWidth="md">
            <Typography style={{ marginTop: '2rem' }} variant="h4" component="h1" color="primary">
                {
                    lang === 'esp' ? 'Cortometraje' : lang === 'eng' ? 'Short Film' : 'Court Métrage'
                }
            </Typography>
            <Grid alignItems="center" css={css`
                padding: 2.5rem 0;
                > div:nth-of-type(even) {
                    margin-left: auto;
                }
            `} container spacing={3}>
                <Grid item xs={12} md={9}>
                    <video css={css`
                        width: 100%;
                        outline: none;
                    `} controls autoPlay src="https://firebasestorage.googleapis.com/v0/b/brandon-linares.appspot.com/o/INNER%20SELF%20NO%20COLOR.mp4?alt=media&token=fba2ad56-6a43-4a95-a298-2acd0d2df80f"></video>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Typography css={css`
                        text-decoration: underline;
                    `} align="center" variant="h4">INNER SELF</Typography>
                </Grid>
            </Grid>
        </Container>
    )
}