import React, { useEffect, useState } from 'react';
import firebase from '../utils/firebase';
import { Container, Typography, Box, Grid } from '@material-ui/core';
import { css } from '@emotion/core';

const Section = ({ data }) => {

    return data.type === 'title' ? (
        <Typography variant="h3" component="h1">
            {data.content}
        </Typography>
    ) : data.type === 'paragraph' ? (
        <Typography variant="body1" paragraph css={css`
            white-space: pre-line;
        `}>{data.content}</Typography>
    ) : data.type === 'image' ? (
        <Box display="flex" justifyContent="center" alignItems="center">
            <img style={{ width: '95%', maxWidth: '40rem' }} src={data.image} alt='Imagen de blog'/>
        </Box>
    ) : data.type === 'image-paragraph' ? (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <img style={{ width: '95%', maxWidth: '40rem' }} src={data.image} alt='Imagen de blog'/>
            </Grid>
            <Grid item xs={12} md={6}>
                <Typography>
                    {data.content}
                </Typography>
            </Grid>
        </Grid>
    ) : data.type === 'paragraph-image' ? (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <Typography>
                    {data.content}
                </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <img style={{ width: '95%', maxWidth: '40rem' }} src={data.image} alt='Imagen de blog'/>
            </Grid>
        </Grid>
    ) : data.type === 'frame' ? (
        <div>
            <iframe title={data.type} width={data.frameStyles.width} height={data.frameStyles.minHeight} src={data.content} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
    ) : ''
}

export default function Single (props) {

    const id = props.match.params.id

    const [content,setcontent] = useState({})
    const [sections,setsections] = useState([])

    useEffect(() => {
        if (id) {
            firebase.getDocument('content',id).get().then(snap => {
                setcontent(snap.data())
            })
            firebase.getDocument('content',id).collection('list').get().then(snap => {
                const data = snap.docs.map(doc => doc.data())
                setsections(data)
            })
        }
    },[id])

    return (
        <Container maxWidth="sm">
            <picture style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ width: '95%', maxWidth: '40rem' }} src={content.image} alt={content.name}/>
            </picture>
            <Typography css={css`
                margin: 2.5rem 0;
            `} variant="h4" component="h1" align="center">{content.name}</Typography>
            {
                sections.map(item => <Section key={item.id} data={item} />)
            }
        </Container>
    )
}