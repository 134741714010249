import app from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

import moment from 'moment'
import _ from 'lodash'

const config = {
    apiKey: "AIzaSyCkH92Mg2BKyDxiDC3lMW0lL4bkF1axBPA",
    authDomain: "brandon-linares.firebaseapp.com",
    databaseURL: "https://brandon-linares.firebaseio.com",
    projectId: "brandon-linares",
    storageBucket: "brandon-linares.appspot.com",
    messagingSenderId: "930180366309",
    appId: "1:930180366309:web:ca31a340187d4c81c3b90c",
    measurementId: "G-MGV1HNQJ56"
}

class Firebase {
    constructor() {
        if (!app.apps.length) {
            app.initializeApp(config)
        }
        this.auth = app.auth()
        this.firestore = app.firestore()
        this.storage = app.storage()
    }

    login(email, password) {
        return this.auth.signInWithEmailAndPassword(email,password)
    }
    async register (user) {
        const timestamp = moment().valueOf()
        await this.auth.createUserWithEmailAndPassword(user.email,user.password)
        user.id = this.auth.currentUser.uid
        user.timestamp = timestamp
        await this.firestore.collection('users').doc(this.auth.currentUser.uid).set(user)
        return user.id
    }
    logout() {
        return this.auth.signOut()
    }

    isInitialized() {
        return new Promise(resolve => {
            this.auth.onAuthStateChanged(resolve)
        })
    }
    userSession() {
        return new Promise(resolve => {
            if (this.auth.currentUser) {
                this.firestore.collection('users').doc(this.auth.currentUser.uid).onSnapshot(snap => {
                    resolve(snap.data())
                })
            }
        })
    }

    async addOnUser(col, item) {
        const ref = this.firestore.collection('users').doc(this.auth.currentUser.uid).collection(col).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        await ref.set(item)
        return ref.id
    }
    getColectionFromUser(col) {
        const ref = this.firestore.collection('users').doc(this.auth.currentUser.uid).collection(col)
        return ref
    }
    async simpleAdd(item,col) {
        const ref = this.firestore.collection(col).doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        item.userId = this.auth.currentUser.uid
        item.userEmail = this.auth.currentUser.email
        await ref.set(item)
        const movesRef = this.firestore.collection('usersMoves').doc()
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Agregar',
            what: ref.id,
            userId: this.auth.currentUser.uid
        }
        await movesRef.set(move)
        return ref.id
    }
    sendMessage(item) {
        const ref = this.firestore.collection('messages').doc()
        item.id = ref.id
        item.timestamp = moment().valueOf()
        return ref.set(item)
    }

    getCollection(col) {
        const ref = this.firestore.collection(col)
        return ref
    }
    getDocument(col,doc) {
        const ref = this.firestore.collection(col).doc(doc)
        return ref
    }
    getLoan(id) {
        const getDoc = new Promise(resolve => {
            this.firestore.collection('loans').doc(id).onSnapshot(snap => {
                if (snap.exists) {
                    const loan = snap.data()
                    if (this.auth.currentUser) {
                        const userId = this.auth.currentUser.uid
                        if (loan.userId === userId || _.find(loan.users, userId)) {
                            resolve(loan)
                        } else {
                            resolve(undefined)
                        }
                    } else {
                        resolve(undefined)
                    }
                } else {
                    resolve(undefined)
                }
            })
        })
        return getDoc
    }
    updateDoc (col,doc,index,value) {
        const ref = this.firestore.collection(col).doc(doc)
        return ref.update({ [index]: value })
    }
    async delete(col,doc,data) {
        const ref = this.firestore.collection(col).doc(doc)
        await ref.delete();
        const movesRef = this.firestore.collection('usersMoves').doc();
        const move = {
            col,
            timestamp: moment().valueOf(),
            id: movesRef.id,
            type: 'Borrar',
            what: ref.id,
            userId: this.auth.currentUser.uid,
            data
        };
        return movesRef.set(move);
    }
    async addFileDoc (col,doc,index,file) {
        const uploadFile = (route,file,name) => {
            return new Promise((resolve,reject) => {
                const ext = file.name.split('.').pop()
                const fileName = `${name}.${ext}`;
                const uploadFile = this.storage.ref(route).child(fileName).put(file);
                uploadFile.on('state_changed', snapshot => {
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    return progress
                }, error => {
                    reject(error)
                }, () => {
                    uploadFile.snapshot.ref.getDownloadURL().then(url => {
                        resolve(url)
                    })
                });
            })
        }
        const ref = this.firestore.collection(col).doc(doc)
        const url = await uploadFile(`${col}/${doc}`,file,index)
        return ref.update({ [index]: url }).then(() => {
            return url
        })
    }
}

export default new Firebase()