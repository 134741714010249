import React, { useEffect, useState } from 'react'
import firebase from '../../utils/firebase'
import { Container, Grid, TextField, Typography, Button, FormControl, InputLabel, Select, Box } from '@material-ui/core'
import Loader from '../../components/Loader'
import AdminProjectItem from '../../components/AdminProjectItem'

export default function AdminSingleProject (props) {

    const id = props.match.params.id
    const [image,setimage] = useState(undefined)
    const [loading,setloading] = useState(false)
    const [content,setcontent] = useState(undefined)
    const [type,settype] = useState('')
    const [list,setList] = useState([])

    useEffect(() => {
        firebase.getDocument('content',id).onSnapshot(snap => {
            setcontent(snap.data())
        })
        firebase.getDocument('content',id).collection('list').orderBy('timestamp').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setList(data)
        })
    },[id])

    return content ? (
        <Box>
            <Grid style={{ backgroundColor: '#f4f2f2' }} container spacing={4}>
                <Grid item xs={12} md={4}>
                    <Typography align="center" style={{ marginBottom: '1rem' }} variant="h5" component="h2">Datos del contenido</Typography>
                    <Button color="primary" onClick={() => firebase.updateDoc('content',id,'status',content.status === 'activo' ? 'inactivo' : 'activo')} variant="outlined" fullWidth>{content.status ? content.status : ''}</Button>
                    <TextField
                        label="Nombre del contenido"
                        value={content.name ? content.name : ''}
                        variant="outlined"
                        fullWidth
                        onChange={e => firebase.updateDoc('content',id,'name',e.target.value)}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                    />
                    <FormControl fullWidth>
                        <InputLabel htmlFor="age-native-simple">Tipo de Contenido</InputLabel>
                        <Select
                            native
                            value={content.type ? content.type : ''}
                            onChange={e => firebase.updateDoc('content',id,'type',e.target.value)}
                            inputProps={{
                                id: 'age-native-simple',
                            }}
                        >
                            <option value="" />
                            <option value="Proyecto">Proyecto</option>
                            <option value="Historia">Historia</option>
                            <option value="Exposiciones">Exposiciones</option>
                        </Select>
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="age-native-simple">Idioma</InputLabel>
                        <Select
                            native
                            value={content.language ? content.language : ''}
                            onChange={e => firebase.updateDoc('content',id,'language',e.target.value)}
                            inputProps={{
                                id: 'age-native-simple',
                            }}
                        >
                            <option value="esp">Español</option>
                            <option value="eng">Inglés</option>
                            <option value="fra">Frances</option>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Descripción corta del proyecto"
                        value={content.description ? content.description : ''}
                        variant="outlined"
                        fullWidth
                        onChange={e => firebase.updateDoc('content',id,'description',e.target.value)}
                        rows="5"
                        multiline={true}
                        style={{ marginTop: '1rem' }}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography align="center" style={{ marginBottom: '1rem' }} variant="h5" component="h2">Imagen de portada</Typography>
                    {content.image ? <img style={{ width: '100%' }} src={content.image} alt={content.name}/> : ''}
                    <TextField
                        label="Imagen"
                        onChange={e => setimage(e.target.files[0])}
                        fullWidth
                        margin="dense"
                        type="file"
                    />
                    <Button disabled={loading} fullWidth size="small" onClick={() => handleFile(image,'image')} color="primary" variant="outlined">Establecer Imagen</Button>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography align="center" style={{ marginBottom: '1rem' }} variant="h5" component="h2">Agregar Secciones</Typography>
                    <FormControl fullWidth>
                        <InputLabel htmlFor="age-native-simple">Sección</InputLabel>
                        <Select
                            native
                            value={type}
                            onChange={e => settype(e.target.value)}
                            inputProps={{
                                id: 'age-native-simple',
                            }}
                        >
                            <option value="" />
                            <option value="title">Título</option>
                            <option value="paragraph">Párrafo</option>
                            <option value="image">Imagen</option>
                            <option value="image-paragraph">Imagen - Párrafo</option>
                            <option value="paragraph-image">Párrafo - Imagen</option>
                        </Select>
                    </FormControl>
                    <Button onClick={handleSubmit} style={{ marginTop: '1rem' }} variant="contained" color="primary" fullWidth>Agregar</Button>
                </Grid>
            </Grid>
            <Box paddingY="2rem">
                <Container maxWidth="md">
                    {
                        list.map(item => <AdminProjectItem key={item.id} contentId={id} data={item} />)
                    }
                </Container>
            </Box>
        </Box>
    ) : <Loader />

    async function handleSubmit () {
        try {
            if (type) {
                const item = {
                    type
                }
                firebase.simpleAdd(item,`/content/${id}/list`).then(() => {
                    settype('')
                    alert('Elemento agregado')
                })
            } else {
                alert('Selecciona un tipo para continuar')
            }
        } catch (error) {
            alert(error.message)
        }
    }

    async function handleFile (file,index) {
        try {
            if (file) {
                setloading(true)
                firebase.addFileDoc('content',id,index,file).then(() => {
                    alert('Imagen establecida')
                    setloading(false)
                })
            } else {
                alert('Selecciona un archivo para continuar')
                setloading(false)
            }
        } catch (error) {
            alert(error.message)
        }
    }
}