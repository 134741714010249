import React from 'react'
import { Container, Typography } from '@material-ui/core'

export default function AdminExpositions () {

    return (
        <Container>
            <Typography variant="h3" component="h1" align="center">Exposiciones - (Próximamente)</Typography>
        </Container>
    )
}