import React, { useState, useEffect } from 'react'
import firebase from '../../utils/firebase'
import { Container, Grid, Card, CardHeader, Avatar, IconButton, CardContent, Typography } from '@material-ui/core'
import _ from 'lodash'

import MessageIcon from '@material-ui/icons/Message';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const Item = ({ data }) => {

    return (
        <Card style={{ marginBottom: '1rem' }}>
            <CardHeader
                avatar={
                <Avatar aria-label="recipe">
                    <MessageIcon />
                </Avatar>
                }
                action={
                    <IconButton onClick={() => firebase.updateDoc('messages',data.id,'isRead',!data.isRead)} color="primary" aria-label="settings">
                        {data.isRead === false ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
                    </IconButton>
                }
                title={data.name}
                subheader={data.email}
            />
            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {data.phone}
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                    {data.message}
                </Typography>
            </CardContent>
        </Card>
    )
}

export default function AdminMessages () {

    const [contact,setContact] = useState([])

    useEffect(() => {
        firebase.getCollection('messages').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setContact(data)
        })
    },[])

    const pending = _.filter(contact, o => {
        return o.isRead === false
    })
    const read = _.filter(contact, o => {
        return o.isRead === true
    })

    return (
        <Container>
            <Typography variant="h3" component="h1" align="center">Mensajes</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    {
                        pending.map(item => <Item key={item.id} data={item} />)
                    }
                </Grid>
                <Grid item xs={12} md={6}>
                    {
                        read.map(item => <Item key={item.id} data={item} />)
                    }
                </Grid>
            </Grid>
        </Container>
    )
}