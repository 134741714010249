import React, { useState } from 'react'
import firebase from '../utils/firebase'
import { TextField, Grid, Button, IconButton } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';

export default function AdminProjectItem ({ data, contentId }) {

    const [file,setfile] = useState('')
    const [loading,setLoading] = useState(false)

    return (
        <div style={{ position: 'relative', padding: '1rem 0' }}>
            <IconButton onClick={() => firebase.delete('content',`${contentId}/list/${data.id}`,data)} color="default" style={{ position: 'absolute', right: 0, bottom: 0, zIndex: 9 }}>
                <DeleteIcon color="error" />
            </IconButton>
            {
                data.type === 'title' ? (
                    <TextField
                        value={data.content ? data.content : ''}
                        variant="outlined"
                        fullWidth
                        label="Título"
                        onChange={e => firebase.updateDoc('content',`${contentId}/list/${data.id}`,'content',e.target.value)}
                    />
                ) : data.type === 'paragraph' ? (
                    <TextField
                        value={data.content ? data.content : ''}
                        variant="outlined"
                        fullWidth
                        label="Parrafo"
                        onChange={e => firebase.updateDoc('content',`${contentId}/list/${data.id}`,'content',e.target.value)}
                        rows="6"
                        multiline={true}
                    />
                ) : data.type === 'image' ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <img style={{ width: '100%' }} src={data.file} alt={data.id}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label="Imagen"
                                onChange={e => setfile(e.target.files[0])}
                                type="file"
                                fullWidth
                            />
                            <Button disabled={loading} onClick={handleFile} variant="contained" fullWidth color="primary">Establecer Imagen</Button>
                        </Grid>
                    </Grid>
                ) : data.type === 'image-paragraph' ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <img style={{ width: '100%' }} src={data.file} alt={data.id}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Imagen"
                                onChange={e => setfile(e.target.files[0])}
                                type="file"
                                fullWidth
                            />
                            <Button disabled={loading} onClick={handleFile} variant="contained" fullWidth color="primary">Establecer Imagen</Button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                value={data.content ? data.content : ''}
                                variant="outlined"
                                fullWidth
                                label="Parrafo"
                                onChange={e => firebase.updateDoc('content',`${contentId}/list/${data.id}`,'content',e.target.value)}
                                rows="6"
                                multiline={true}
                            />
                        </Grid>
                    </Grid>
                ) : data.type === 'paragraph-image' ? (
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <TextField
                                value={data.content ? data.content : ''}
                                variant="outlined"
                                fullWidth
                                label="Parrafo"
                                onChange={e => firebase.updateDoc('content',`${contentId}/list/${data.id}`,'content',e.target.value)}
                                rows="6"
                                multiline={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <img style={{ width: '100%' }} src={data.file} alt={data.id}/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                label="Imagen"
                                onChange={e => setfile(e.target.files[0])}
                                type="file"
                                fullWidth
                            />
                            <Button disabled={loading} onClick={handleFile} variant="contained" fullWidth color="primary">Establecer Imagen</Button>
                        </Grid>
                    </Grid>
                ) : ''
            }
        </div>
    )

    async function handleFile () {
        try {
            setLoading(true)
            if (file) {
                firebase.addFileDoc('content',`${contentId}/list/${data.id}`,'file',file).then(() => {
                    setLoading(false)
                })
            } else {
                alert('Selecciona una imagen para continuar')
                setLoading(false)
            }
        } catch (error) {
            alert(error.message)
        }
    }
}