import React from 'react'
import { Container, Typography, Paper } from '@material-ui/core'
import { css } from '@emotion/core'

export default function Home () {

    return (
        <>
            <Container>
                <div css={css`
                    display: flex;
                    align-items: center;
                    img {
                        width: 100%;
                    }
                `}>
                    <picture css={css`
                        flex: 4;
                    `}>
                        <img src="/images/cv1.jpg" alt="Brandon Linares Leónn"/>
                    </picture>
                    <picture css={css`
                        flex: 5;
                    `}>
                        <img src="/images/cv2.jpg" alt="Brandon Linares Leónn"/>
                    </picture>
                </div>
            </Container>
        </>
    )
}