import React, { useState } from 'react'
import firebase from '../utils/firebase'
import { Container, Paper, TextField, Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    input: {
        marginBottom: theme.spacing(2)
    },
    container: {
        padding: theme.spacing(3),
        marginTop: theme.spacing(4)
    }
}))

export default function Contact ({ lang }) {

    const classes = useStyles()
    const [name,setname] = useState('')
    const [email,setemail] = useState('')
    const [phone,setphone] = useState('')
    const [message,setmessage] = useState('')

    return (
        <Container maxWidth="xs">
            <Paper className={classes.container}>
                <Typography color="primary" align="center" style={{ textTransform: 'uppercase', letterSpacing: '3px', marginBottom: '2rem' }} variant="h5" component="h1">
                    {
                        lang === 'esp' ? 'Envíame un mensaje' : lang === 'fra' ? 'Envoie moi un message' : 'Send me a message'
                    }
                </Typography>
                <TextField
                    label={
                        lang === 'esp' ? 'Nombre' : lang === 'fra' ? 'Nom' : 'Name'
                    }
                    variant="outlined"
                    value={name}
                    onChange={e => setname(e.target.value)}
                    fullWidth
                    className={classes.input}
                />
                <TextField
                    label={
                        lang === 'esp' ? 'Teléfono' : lang === 'fra' ? 'Télépone' : 'Phone'
                    }
                    variant="outlined"
                    value={phone}
                    onChange={e => setphone(e.target.value)}
                    fullWidth
                    className={classes.input}
                />
                <TextField
                    label={
                        lang === 'esp' ? 'Email' : lang === 'fra' ? 'Email' : 'Email'
                    }
                    variant="outlined"
                    value={email}
                    onChange={e => setemail(e.target.value)}
                    fullWidth
                    className={classes.input}
                    type="mail"
                />
                <TextField
                    label={
                        lang === 'esp' ? 'Mensaje' : lang === 'fra' ? 'Message' : 'Message'
                    }
                    variant="outlined"
                    value={message}
                    onChange={e => setmessage(e.target.value)}
                    fullWidth
                    className={classes.input}
                    multiline
                    rows="6"
                />
                <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth style={{ color: '#fff' }}>
                    {
                        lang === 'esp' ? 'Enviar mensaje' : lang === 'fra' ? 'Envoyer un message' : 'Send message'
                    }
                </Button>
            </Paper>
        </Container>
    )

    async function handleSubmit () {
        try {
            if (name && email && message) {
                const item = {
                    name,
                    email,
                    phone,
                    message,
                    isRead: false
                }
                firebase.sendMessage(item).then(() => {
                    setname('')
                    setemail('')
                    setphone('')
                    setmessage('')
                    alert('Mensaje enviado')
                })
            } else {
                alert('Completa los campos nombre, email y mensaje')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}