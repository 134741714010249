import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'
import { Link } from 'react-router-dom'
import { Container, Box, Typography, Button } from '@material-ui/core'
import Carousel from 'react-material-ui-carousel'

function Item({ item }) {
    return (
        <div>
            <picture style={{ border: '50px solid transparent', borderImage: 'url(/images/border.png) 50', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img style={{ width: '100%' }} src={item.image} alt={item.name} />
            </picture>
            <Box display="flex" flexDirection="column" padding=".5rem">
                <h2>{item.name}</h2>
                <Button component={Link} to={`/item/${item.id}`} style={{ marginLeft: 'auto' }} variant="contained" color="primary" className="CheckButton">
                    Ver detalle
                </Button>
            </Box>
        </div>
    )
}

export default function Projects ({ lang }) {

    const [projects,setProjects] = useState([])

    useEffect(() => {
        firebase.getCollection('content').where('type','==','Proyecto').where('status','==','activo').where('language','==',lang).get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setProjects(data)
        })
    },[lang])

    return (
        <Box minHeight="80vh">
            <Container>
                <Typography variant="h3" component="h1" color="primary">
                    {
                        lang === 'esp' ? 'Proyectos' : lang === 'eng' ? 'Projects' : 'Les Projets'
                    }
                </Typography>
                <Box width="95%" maxWidth="40rem" style={{ margin: '2.5rem auto' }}>
                    <Carousel>
                        {
                            projects.map(item => 
                                <Item key={item.id} item={item} />
                            )
                        }
                    </Carousel>
                </Box>
            </Container>
        </Box>
    )
}