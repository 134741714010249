import React, { useState, useEffect } from 'react'
import firebase from '../../utils/firebase'
import { Link } from 'react-router-dom'
import { Container, Grid, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, IconButton } from '@material-ui/core'
import moment from 'moment'

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

export default function AdminContent () {

    const [name,setname] = useState('')
    const [content,setcontent] = useState([])

    useEffect(() => {
        firebase.getCollection('content').onSnapshot(snap => {
            const data = snap.docs.map(doc => doc.data())
            setcontent(data)
        })
    },[])

    return (
        <Container>
            <Grid component="form" onSubmit={e => e.preventDefault() && false} alignItems="center" container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        label="Nombre de Contenido"
                        variant="outlined"
                        fullWidth
                        value={name}
                        onChange={e => setname(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button type="submit" onClick={handleSubmit} fullWidth variant="contained" color="primary" size="large">Agregar</Button>
                </Grid>
            </Grid>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Fecha de creación</TableCell>
                        <TableCell>Nombre de contenido</TableCell>
                        <TableCell>Estatus</TableCell>
                        <TableCell>Ver más</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{
                    content.map(item => <TableRow key={item.id}>
                        <TableCell>{moment(item.timestamp).format('DD-MM-YYYY')}</TableCell>
                        <TableCell>{item.name}</TableCell>
                        <TableCell>{item.status}</TableCell>
                        <TableCell><IconButton color="secondary" component={Link} to={`/admin/content/${item.id}`}><ArrowForwardIosIcon/></IconButton></TableCell>
                    </TableRow>)
                }</TableBody>
            </Table>
        </Container>
    )

    async function handleSubmit () {
        try {
            if (name) {
                const item = {
                    name,
                    status: 'inactivo',
                    language: 'esp'
                }
                firebase.simpleAdd(item, 'content').then(() => {
                    setname('')
                    alert('Proyecto agregado')
                })
            }
        } catch (error) {
            alert(error.message)
        }
    }
}