import { createMuiTheme } from '@material-ui/core/styles';
import { red, grey, blueGrey } from '@material-ui/core/colors';

// Create a theme instance.
const theme = createMuiTheme({
	palette: {
		primary: {
			main: grey[900]
		},
		secondary: {
			main: blueGrey[500],
			contrastText: '#fff',
		},
		error: {
			main: red.A400,
		},
		background: {
			default: '#68A6A0',
		},
	},
	typography: {
		fontFamily: [
		  '"Oswald"',
		  'sans-serif',
		].join(','),
		fontSize: 16
	  },
});

export default theme;