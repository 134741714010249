import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'
import { Link } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@material-ui/core'

import TextureIcon from '@material-ui/icons/Texture';

function Item({ item }) {
    return (
        <Box display="flex" padding=".5rem" alignItems="center" marginBottom="2.5rem">
            <TextureIcon style={{ marginRight: '1rem' }} />
            <Typography color="primary" component={Link} to={`/item/${item.id}`} variant="h5">{item.name}</Typography>
        </Box>
    )
}

export default function Stories ({ lang }) {
    const [projects,setProjects] = useState([])

    useEffect(() => {
        firebase.getCollection('content').where('type','==','Historia').where('status','==','activo').where('language','==',lang).get().then(snap => {
            const data = snap.docs.map(doc => doc.data())
            setProjects(data)
        })
    },[lang])

    return (
        <Box minHeight="80vh">
            <Container>
                <Typography style={{ marginTop: '2rem' }} variant="h4" component="h1" color="primary">
                    {
                        lang === 'esp' ? 'Historias' : lang === 'eng' ? 'Stories' : 'Les Histoires'
                    }
                </Typography>
                <Box width="95%" maxWidth="40rem" style={{ margin: '2.5rem auto', display: 'flex', flexDirection: 'column' }}>
                    {
                        projects.map(item => 
                            <Item key={item.id} item={item} />
                        )
                    }
                </Box>
            </Container>
        </Box>
    )
}