import React, { useState, useEffect } from 'react'
import firebase from '../utils/firebase'
import { Container, Typography, TextField, Button, Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    input: {
        marginTop: theme.spacing(2)
    }
}))

export default function Login (props) {

    const classes = useStyles()

    const [email,setemail] = useState('')
    const [password,setpassword] = useState('')

    useEffect(() => {
        firebase.isInitialized().then(user => {
            if (user) {
                props.history.push('/admin')
            }
        })
    },[props.history])

    return (
        <Container maxWidth="xs" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '70vh' }}>
            <Paper style={{ padding: '2rem' }}>
                <Typography align="center" variant="h4" component="h1">Iniciar Sesión</Typography>
                <TextField
                    label="Email"
                    value={email}
                    onChange={e => setemail(e.target.value)}
                    fullWidth
                    variant="outlined"
                    className={classes.input}
                />
                <TextField
                    label="Contraseña"
                    value={password}
                    onChange={e => setpassword(e.target.value)}
                    fullWidth
                    variant="outlined"
                    className={classes.input}
                    type="password"
                />
                <Button onClick={handleSubmit} fullWidth className={classes.input} variant="contained" color="primary">Entrar</Button>
            </Paper>
        </Container>
    )

    async function handleSubmit () {
        try {
            if (email && password) {
                firebase.login(email,password).then(() => {
                    props.history.push('/admin')
                }).catch(error => {
                    alert(error.message)
                })
            } else {
                alert('Completa todos los campos')
            }
        } catch (error) {
            alert(error.message)
        }
    }
}